<template>
  <div class="contact-container">
    <Header />
    <Banner
      v-if="bannerInfo.pic"
      :src="bannerInfo.pic"
      :text="bannerInfo.text"
    />
    <div class="form-container center">
      <div class="left-text">
        <div class="note">{{ cont.text1 }}</div>
        <a :href="'mailto:' + cont.email" class="email">{{ cont.email }}</a>
      </div>
      <div class="right-form">
        <div class="title">
          For all other enquiries, please complete the form below:
        </div>
        <form class="message" @submit.prevent="handleSubmit">
          <label for="">
            <select v-model="formData.generalEnquiries">
              <option value="General Enquiries">General Enquiries</option>
            </select>
            <i class="iconfont icon-jiantou8"></i>
          </label>
          <label for="">
            <span>Name*</span>
            <input type="text" v-model="formData.name" />
          </label>
          <label for="">
            <span>Email*</span>
            <input type="text" v-model="formData.Email" />
          </label>
          <label for="">
            <span>Message*</span>
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              v-model="formData.Message"
            ></textarea>
          </label>
          <button class="submit">
            <span>Submit</span>
            <i class="iconfont icon-arrowRight"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="contact-info center">
      <div class="left-map">
        <div
          class="map"
          :style="{
            backgroundImage: `url(${cont.map})`,
          }"
        ></div>
      </div>
      <div class="right-info">
        <div class="title">{{ cont.title }}</div>
        <ul class="contact-list">
          <li class="contact-item">
            <div class="name">Address</div>
            <div class="text">
              {{ cont.address }}
            </div>
          </li>
          <li class="contact-item">
            <div class="name">Email</div>
            <div class="text">
              <a :href="'mailto:' + cont.email" class="email">{{
                cont.email
              }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Banner from "@/components/Banner";
import Footer from "@/components/Footer";
import mainScroll from "@/mixins/mainScroll";
import getBanner from "@/mixins/getBanner";
import { getContactCont } from "@/api/contactContent";
import { formSubmit } from "@/api/formSubmit";
import "element-ui/lib/theme-chalk/index.css";
import { Message } from "element-ui";

export default {
  mixins: [mainScroll(), getBanner(6)],
  data() {
    return {
      cont: {},
      formData: {
        generalEnquiries: "General Enquiries",
        name: "",
        Email: "",
        Message: "",
      },
    };
  },
  components: {
    Header,
    Banner,
    Footer,
  },
  async created() {
    this.cont = await this.getContent(81);
  },
  methods: {
    async getContent(cont) {
      return await getContactCont(cont);
    },
    async handleSubmit(e) {
      const result = await formSubmit(this.formData);
      console.log(result);
      if (result.data) {
        Message.error({
          message: result.data,
        });
        return;
      }
      Message.success({
        message: result,
      });
      this.$delete(this.formData, "Email");
      this.$delete(this.formData, "name");
      this.$delete(this.formData, "Message");
      e.preventDefault();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.form-container {
  padding: 80px 0;
  display: flex;
  justify-content: space-between;
  .left-text {
    width: 50%;
    font-size: 28pt;
    font-family: @fontB;
    line-height: 1.3;
    border-right: 1px solid @assistColor;
    .note {
      width: 80%;
    }
    a.email {
      color: lighten(@mainColor, 15%);
      word-break: break-all;
      display: block;
      width: 90%;
    }
  }
  .right-form {
    width: 50%;
    padding: 0 50px;
    box-sizing: border-box;
    .title {
      font-size: 28pt;
      font-family: @fontB;
      color: @mainColor;
      margin-bottom: 40px;
    }
    label {
      display: block;
      width: 100%;
      margin-bottom: 50px;
      position: relative;
      select,
      input,
      textarea {
        width: 100%;
        box-sizing: border-box;
        font-size: 20pt;
        padding-left: 30px;
        box-sizing: border-box;
        font-family: @fontB;
        color: @gray;
        border: 2px solid #ccc;
      }
      select,
      input {
        height: 70px;
      }
      textarea {
        height: 180px;
        padding-top: 15px;
      }
      select {
        appearance: none;
      }
      i.iconfont {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 20pt;
        color: #ccc;
      }
      span {
        display: block;
        font-size: 18pt;
        margin-bottom: 5px;
      }
    }
    button.submit {
      width: 50%;
      height: 70px;
      background-color: @assistColor;
      border: none;
      outline: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      cursor: pointer;
      span {
        font-size: 21pt;
        font-family: @fontB;
      }
      i.iconfont {
        font-size: 28pt;
      }
    }
  }
}
.contact-info {
  display: flex;
  flex-wrap: wrap;
  background-color: @lightGray;
  margin-bottom: 100px;
  .left-map {
    width: 64%;
    .map {
      width: 100%;
      padding-top: 72%;
      .bgImg(cover);
    }
  }
  .right-info {
    width: 36%;
    box-sizing: border-box;
    padding: 50px;
    .title {
      font-size: 30pt;
      font-family: @fontB;
      padding-bottom: 60px;
      margin-bottom: 60px;
      border-bottom: 2px solid @assistColor;
    }
    ul.contact-list {
      li.contact-item {
        margin-bottom: 50px;
        .name {
          font-size: 16pt;
          color: @gray;
          font-family: @fontB;
          margin-bottom: 20px;
        }
        .text {
          font-size: 18pt;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .form-container {
    padding: 60px 0;
    .left-text {
      font-size: 24pt;
    }
    .right-form {
      padding: 0 30px;
      .title {
        font-size: 24pt;
        margin-bottom: 30px;
      }
      label {
        margin-bottom: 30px;
        select,
        input,
        textarea {
          font-size: 18pt;
          padding-left: 20px;
        }
        select,
        input {
          height: 60px;
        }
        textarea {
          height: 160px;
          padding-top: 15px;
        }
        i.iconfont {
          font-size: 16pt;
        }
        span {
          font-size: 16pt;
          margin-bottom: 5px;
        }
      }
      button.submit {
        width: 50%;
        height: 60px;
        padding: 10px 20px;
        cursor: pointer;
        span {
          font-size: 18pt;
          font-family: @fontB;
        }
        i.iconfont {
          font-size: 24pt;
        }
      }
    }
  }
  .contact-info {
    margin-bottom: 80px;
    .left-map {
      width: 60%;
    }
    .right-info {
      width: 40%;
      box-sizing: border-box;
      padding: 30px;
      .title {
        font-size: 20pt;
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
      ul.contact-list {
        li.contact-item {
          margin-bottom: 30px;
          .name {
            font-size: 14pt;
            color: @gray;
            font-family: @fontB;
            margin-bottom: 10px;
          }
          .text {
            font-size: 16pt;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .form-container {
    padding: 40px 0;
    .left-text {
      font-size: 20pt;
    }
    .right-form {
      padding: 0 20px;
      .title {
        font-size: 20pt;
        margin-bottom: 20px;
      }
      label {
        margin-bottom: 20px;
        select,
        input,
        textarea {
          font-size: 14pt;
          padding-left: 10px;
        }
        select,
        input {
          height: 48px;
        }
        textarea {
          height: 120px;
          padding-top: 15px;
        }
        i.iconfont {
          font-size: 14pt;
        }
        span {
          font-size: 14pt;
        }
      }
      button.submit {
        width: 70%;
        height: 48px;
        padding: 10px 20px;
        cursor: pointer;
        span {
          font-size: 16pt;
          font-family: @fontB;
        }
        i.iconfont {
          font-size: 18pt;
        }
      }
    }
  }
  .contact-info {
    margin-bottom: 60px;
    .left-map {
      width: 50%;
      .map {
        padding-top: 100%;
      }
    }
    .right-info {
      width: 50%;
      box-sizing: border-box;
      padding: 20px;
      .title {
        font-size: 18pt;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      ul.contact-list {
        li.contact-item {
          margin-bottom: 20px;
          .name {
            font-size: 12pt;
          }
          .text {
            font-size: 14pt;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .form-container {
    padding: 30px 0;
    flex-wrap: wrap;
    .left-text {
      width: 100%;
      font-size: 14pt;
      border-right: none;
      border-bottom: 1px solid @assistColor;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .right-form {
      width: 100%;
      padding: 0;
      .title {
        font-size: 14pt;
        margin-bottom: 10px;
      }
      label {
        margin-bottom: 20px;
        select,
        input,
        textarea {
          font-size: 12pt;
          padding-left: 10px;
          border-width: 1px;
        }
        select,
        input {
          height: 48px;
        }
        textarea {
          height: 120px;
          padding-top: 15px;
        }
        i.iconfont {
          font-size: 14pt;
        }
        span {
          font-size: 14pt;
        }
      }
      button.submit {
        width: 100%;
      }
    }
  }
  .contact-info {
    margin-bottom: 60px;
    .left-map {
      width: 100%;
      .map {
        padding-top: 100%;
      }
    }
    .right-info {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
      .title {
        font-size: 18pt;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      ul.contact-list {
        li.contact-item {
          margin-bottom: 20px;
          .name {
            font-size: 12pt;
          }
          .text {
            font-size: 14pt;
          }
        }
      }
    }
  }
}
</style>