import axios from "./request";
import {delHtmlTag} from '@/utils';
export async function getAboutIconList(sort) {
  var result = await axios.get(`/api.php/list/${sort}`);
  var list = result.map((item) => {
    return {
      title: item.title,
      desc: delHtmlTag(item.content),
      ico: window.website + item.ico,
      id:item.id
    };
  });
  return list;
}
