import { getSiteInfo, getSiteLabel } from "@/api/siteInfo";
export default {
  namespaced: true,
  state: {
    siteInfo: {},
    siteLabel: {},
  },
  mutations: {
    setInfoData(state, payload) {
      state.siteInfo = payload;
    },
    setLabelData(state, payload) {
      state.siteLabel = payload;
    },
  },
  actions: {
    async getSiteInfoData(ctx) {
      if (!ctx.state.siteInfo) {
        return;
      }
      const resp = await getSiteInfo();
      ctx.commit("setInfoData", resp);
    },
    async getSiteLabelData(ctx) {
      if (!ctx.state.siteLabel) {
        return;
      }
      const resp = await getSiteLabel();
      ctx.commit("setLabelData", resp);
    },
  },
};
