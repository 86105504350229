import axios from "./request";

export default async function getNav() {
  const result = await axios.post("/api.php/cms/nav");
  return result.map((item) => {
    const { id, name, filename, keywords } = item;
    return {
      id,
      name,
      anchor: filename ? "#" + filename : "",
      link: keywords,
    };
  });
}
