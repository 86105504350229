<template>
  <div id="app">
    <router-view/>
    <ToTop />
  </div>
</template>
<script>
import ToTop from "./components/ToTop";
export default {
  components:{
    ToTop
  }
}
</script>
<style lang="less">
html,
body{
  scroll-behavior: smooth;
}
</style>
