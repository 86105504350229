import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./eventBus";
Vue.config.productionTip = false;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
window.website = "http://inceptiongrowth.relx001.com";
document.title = "INCEPTION GROWTH ACQUISITION LIMITED";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
