<template>
  <div class="home-container">
    <Header />
    <Banner
      v-if="bannerInfo.pic"
      :src="bannerInfo.pic"
      :text="bannerInfo.text"
      :isHome="true"
    />
    <AnchorList :list="homeList" />
    <ul class="cont-list center">
      <li
        class="cont-item"
        v-for="item in homeList"
        :key="item.id"
        :id="item.anchor"
      >
        <div class="text">
          <div class="title">{{ item.title }}</div>
          <div class="note">{{ item.content }}</div>
          <router-link :to="{ name: item.link,hash:item.linkAnchor }" class="more">
            {{ item.linkText }}
          </router-link>
        </div>
        <div class="img">
          <img :src="item.ico" :alt="item.title" />
        </div>
      </li>
    </ul>
    <Footer />
  </div>
</template>
<script>
// @ is an alias to /src

import Header from "@/components/Header";
import Banner from "@/components/Banner";
import AnchorList from "@/components/AnchorList";
import Footer from "@/components/Footer";
import mainScroll from "@/mixins/mainScroll";
import getBanner from "@/mixins/getBanner";
import { getHomeList } from "@/api/homeList";
export default {
  mixins: [getBanner(1), mainScroll()],
  data() {
    return {
      homeList: [],
    };
  },
  async created() {
    this.homeList = await this.getList(1);
  },
  methods: {
    async getList(sort) {
      const result = await getHomeList(sort);
      return result;
    },
  },
  name: "Home",
  components: {
    Header,
    Banner,
    AnchorList,
    Footer,
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";

.cont-list {
  padding: 80px 0;
  .cont-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 80px;
    .text,
    .img {
      width: 50%;
    }
    .text {
      padding-right: 10%;
      box-sizing: border-box;
      .title {
        color: @mainColor;
        font-family: @fontB;
        font-size: 36pt;
        margin-bottom: 20px;
      }
      .note {
        font-size: 16pt;
        color: @gray;
        margin-bottom: 20px;
      }
      .more {
        display: inline-block;
        background-color: @assistColor;
        color: @mainColor;
        font-size: 16pt;
        font-family: @fontB;
        padding: 15px 30px;
      }
    }
    .img {
      position: relative;
      img {
        width: 100%;
      }
    }
    &:nth-child(2n + 2) {
      flex-direction: row-reverse;
      .text {
        padding-left: 5%;
        padding-right: 0;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .cont-list {
    padding: 60px 0;
    .cont-item {
      margin-bottom: 40px;
      .text {
        padding-right: 5%;
        .title {
          font-size: 24pt;
          margin-bottom: 10px;
        }
        .note {
          font-size: 14pt;
          margin-bottom: 10px;
        }
        .more {
          font-size: 14pt;
          padding: 10px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .cont-list {
    padding: 20px 0;
    .cont-item {
      margin-bottom: 20px;
      .text {
        padding-right: 5%;
        .title {
          font-size: 14pt;
          margin-bottom: 10px;
        }
        .note {
          font-size: 10pt;
          margin-bottom: 10px;
        }
        .more {
          font-size: 10pt;
          padding: 10px 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .cont-list {
    padding: 20px 0;
    .cont-item {
      margin-bottom: 30px;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      .text,
      .img {
        width: 100%;
      }
      .img {
        margin-bottom: 10px;
      }
      .text {
        padding-right: 0;
        margin-bottom: 10px;
        .title {
          font-size: 14pt;
          margin-bottom: 10px;
        }
        .note {
          font-size: 10pt;
          margin-bottom: 10px;
        }
        .more {
          font-size: 10pt;
          padding: 10px 20px;
        }
      }
      &:nth-child(2n + 2) {
        flex-direction: column-reverse;
        .text {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
}
</style>
