export default function() {
  return {
    mounted() {
      this.$bus.$on("setMainScroll", this.handleSetMainScroll);
      window.addEventListener("scroll", this.handleMainScroll);
    },
    beforeDestroy() {
      this.$bus.$emit("mainScroll");
      this.$bus.$off("setMainScroll", this.handleSetMainScroll);
      window.removeEventListener("scroll", this.handleMainScroll);
    },
    methods: {
      handleSetMainScroll(scrollTop) {
        document.documentElement.scrollTop = scrollTop;
      },
      handleMainScroll() {
        this.$bus.$emit("mainScroll", document.documentElement);
      },
    },
  };
}
