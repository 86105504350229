<template>
  <div @click="handleClick" v-show="show" class="to-top-container">
    <i class="iconfont icon-up_to_top"></i>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$bus.$on("mainScroll", this.handleScroll);
  },
  destroyed() {
    this.$bus.$off("mainScroll", this.handleScroll);
  },
  methods: {
    handleScroll(dom) {
      if (!dom) {
        this.show = false;
        return;
      }
      this.show = dom.scrollTop >= 500;
    },
    handleClick() {
      // 回到顶部
      this.$bus.$emit("setMainScroll", 0);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.to-top-container {
  width: 74px;
  height: 74px;
  background-color: #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: fixed;
  right: 5%;
  bottom: 10%;
  i.iconfont {
    font-size: 36pt;
    text-align: center;
  }
}
@media screen and (max-width: 1440px) {
  .to-top-container {
    width: 60px;
    height: 60px;
    right: 5%;
    bottom: 10%;
    i.iconfont {
      font-size: 30pt;
    }
  }
}
@media screen and (max-width: 768px) {
  .to-top-container {
    width: 48px;
    height: 48px;
    right: 5%;
    bottom: 10%;
    i.iconfont {
      font-size: 24pt;
    }
  }
}
</style>