import axios from "./request";
export async function getContactCont(cont) {
  var result = await axios.get(`/api.php/content/${cont}`);
  return {
    title: result.title,
    map: window.website + result.ico,
    text1: result.ext_text1,
    email: result.ext_email,
    address: result.ext_address
  };
}
