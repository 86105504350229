<template>
  <div class="header-container">
    <router-link class="logo" :to="{ name: 'Home' }">
      <img :src="siteInfo.logo" alt="" />
      <span>{{siteInfo.title}}</span>
    </router-link>
    <div class="menu-right" :class="{ on: menuIsOpen }">
      <div class="menu">
        <span>MENU</span>
        <i class="menu-btn iconfont icon-menu" @click="menuOpen"></i>
      </div>
      <!-- <div class="language">
        <a href="">EN</a>
        <a href="">CN</a>
      </div> -->
    </div>
    <aside class="aside-menu" :class="{ on: menuIsOpen }">
      <div class="head">
        <i class="iconfont icon-baseline-close-px" @click="menuHide"></i>
        <!-- <div class="language">
          <a href="">EN</a>
          <a href="">CN</a>
        </div> -->
      </div>
      <ul class="nav-list">
        <li class="nav-item" v-for="item in navList" :key="item.id">
          <router-link
            :to="{
              name: item.link,
              hash: item.anchor,
            }"
            >{{ item.name }}</router-link
          >
        </li>
      </ul>
    </aside>
    <div class="mask" v-show="menuIsOpen"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      menuIsOpen: false,
    };
  },
  async created() {
    this.$store.dispatch("webInfo/getSiteInfoData");
    this.$store.dispatch("nav/getNavList");
  },
  methods: {
    menuOpen() {
      this.menuIsOpen = true;
    },
    menuHide() {
      this.menuIsOpen = false;
    },
  },
  computed: {
    ...mapState("webInfo", ["siteInfo"]),
    ...mapState("nav", ["navList"]),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% / 3 * 2);
    padding: 15px 0;
    margin-left: 10%;
    box-sizing: border-box;
    img {
      height: 50px;
    }
    span {
      padding-left: 15px;
      font-size: 20pt;
      font-family: "Futura";
      font-weight: bold;
      letter-spacing: 5px;
    }
  }
  .menu-right {
    background-color: @mainColor;
    width: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    box-sizing: border-box;
    padding: 0 50px;
    font-family: "Raleway-r";
    font-size: 18pt;
    transition: 0.6s;

    .menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .menu-btn {
        margin-left: 30px;
        font-size: 20pt;
        cursor: pointer;
      }
    }
    .language {
      a {
        padding: 0px 10px;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &.on {
      transform: translateY(-100%);
    }
  }
  aside.aside-menu {
    transform: translateX(100%);
    z-index: 999;
    transition: 0.6s;
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: calc(100% / 3);
    background-color: @mainColor;
    padding: 30px 50px;
    box-sizing: border-box;
    color: #fff;
    font-family: "Raleway-r";
    .head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
      i.iconfont {
        font-size: 32px;
        cursor: pointer;
      }
      .language {
        a {
          font-size: 18pt;
          padding: 0 10px;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
    ul.nav-list {
      li.nav-item {
        font-size: 25pt;
        padding: 20px 0;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    &.on {
      transform: translateX(0);
    }
  }
  .mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 998;
  }
}
@media screen and (max-width: 1440px) {
  .header-container {
    .logo {
      margin-left: 5%;
      span {
        font-size: 16pt;
        letter-spacing: 2px;
      }
    }
    .menu-right {
      padding: 0 20px;
      font-size: 14pt;
      .menu {
        .menu-btn {
          margin-left: 10px;
          font-size: 14pt;
        }
      }
      .language {
        a {
          padding: 0px 5px;
        }
      }
    }
    aside.aside-menu {
      padding: 20px 30px;
      .head {
        margin-bottom: 30px;
        i.iconfont {
          font-size: 24px;
        }
        .language {
          a {
            font-size: 14pt;
            padding: 0 5px;
          }
        }
      }
      ul.nav-list {
        li.nav-item {
          font-size: 18pt;
          padding: 10px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .header-container {
    flex-wrap: wrap;
    .logo {
      width: 100%;
      height: 66px;
      img {
        height: 36px;
      }
      span {
        font-size: 10pt;
        letter-spacing: 0;
      }
    }
    .menu-right {
      width: 100%;
      height: 66px;
      font-size: 14pt;
      .menu {
        .menu-btn {
          margin-left: 10px;
          font-size: 14pt;
        }
      }
      .language {
        a {
          padding: 0px 5px;
        }
      }
    }
    aside.aside-menu {
      transform: translate(0, -100%);
      width: 100%;
      padding: 20px 30px;
      .head {
        margin-bottom: 20px;
        .language {
          a {
            font-size: 14pt;
            padding: 0 5px;
          }
        }
      }
      ul.nav-list {
        li.nav-item {
          font-size: 14pt;
          padding: 8px 0;
        }
      }
    }
  }
}
</style>