import Vue from 'vue'
import Vuex from 'vuex'
import webInfo from './webInfo';
import nav from './nav';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    webInfo,
    nav
  },
  strict: true,
})
