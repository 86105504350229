import axios from "./request";
export async function getTeamList(sort) {
  var result = await axios.get(`/api.php/list/${sort}`);
  var list = result.map((item) => {
    return {
      title: item.title,
      content: item.content,
      subtitle: item.subtitle,
      ico: window.website + item.ico,
      id:item.id
    };
  });
  return list;
}
