<template>
  <div class="footer-container">
    <div class="center">
      <div class="left">
        <div class="logo">
          <img :src="siteLabel.logo" :alt="siteInfo.title" />
        </div>
        <div class="text">
          <div class="copyright">{{ siteInfo.copyright }}</div>
          <div class="links">
            <a href="">TEAMS</a>
            <a href="">PRIVACY</a>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="menu-text">Menu</div>
        <ul class="menu-list">
          <li class="menu-item" v-for="item in navList" :key="item.id">
            <router-link
              :to="{
                name: item.link,
                hash: item.anchor,
              }"
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  async created() {
    this.$store.dispatch("webInfo/getSiteInfoData");
    this.$store.dispatch("webInfo/getSiteLabelData");
        this.$store.dispatch("nav/getNavList");
  },
  computed: {
    ...mapState("webInfo", ["siteInfo", "siteLabel"]),
    ...mapState("nav", ["navList"]),
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.footer-container {
  background-color: @mainColor;
  padding: 70px 0;
  .center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 50%;
      .logo {
        height: 88px;
        margin-bottom: 50px;
        img {
          height: 100%;
        }
      }
      .text {
        font-size: 13pt;
        color: #fff;
        display: flex;
        align-items: center;
        .copyright {
          margin-right: 50px;
        }
        .links {
          a {
            padding: 0 30px;
          }
        }
      }
    }
    .right {
      width: 40%;
      display: flex;
      justify-content: center;
      .menu-text {
        font-size: 14pt;
        font-family: @fontB;
        margin-right: 80px;
        color: #fff;
      }
      ul.menu-list {
        li.menu-item {
          color: #fff;
          font-size: 14pt;
          padding: 5px 0;
          &:first-child {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .footer-container {
    padding: 50px 0;
    .center {
      .left {
        width: 50%;
        .logo {
          height: 68px;
          margin-bottom: 30px;
        }
        .text {
          font-size: 11pt;
          flex-wrap: wrap;
          .copyright {
            margin-right: 0;
            width: 100%;
            margin-bottom: 10px;
          }
          .links {
            a {
              padding: 0 20px 0 0;
            }
          }
        }
      }
      .right {
        .menu-text {
          font-size: 12pt;
          margin-right: 40px;
        }
        ul.menu-list {
          li.menu-item {
            color: #fff;
            font-size: 12pt;
            padding: 5px 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .footer-container {
    padding: 30px 0;
    .center {
      .left {
        width: 50%;
        .logo {
          height: 48px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .footer-container {
    padding: 30px 0;
    .center {
      flex-wrap: wrap;
      .left,
      .right {
        width: 100%;
      }
      .left {
        margin-bottom: 40px;
        text-align: center;
        .logo {
          height: 48px;
          margin-bottom: 20px;
          img {
            display: block;
            margin: 0 auto;
          }
        }
        .text {
          .links {
            width: 100%;
            a {
              padding: 0 10px;
            }
          }
        }
      }
      .right {
        .menu-text {
          display: none;
        }
        ul.menu-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          li.menu-item {
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>