import axios from "./request";
import {delHtmlTag} from '@/utils';
export async function getAboutList(sort) {
  var result = await axios.get(`/api.php/list/${sort}`);
  var list = result.map((item,index) => {
    return {
      title: item.title,
      content: delHtmlTag(item.content),
      subtitle: item.title,
      anchor: "about" + index
    };
  });
  return list;
}
