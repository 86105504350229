<template>
  <ul class="anchor-list-container">
    <li class="anchor-item" v-for="item in list" :key="item.id">
      <a :href="'#' + item.anchor">{{ item.subtitle }}</a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/var.less";
.anchor-list-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 10%;
  top: -40px;
  .anchor-item {
    background-color: @assistColor;
    color: @mainColor;
    font-size: 18pt;
    padding: 0 45px;
    line-height: 90px;
    height: 90px;
    box-sizing: border-box;
    a {
      display: block;
    }
    &:hover,
    &.active {
      font-weight: bold;
      background-color: #fff;
    }
  }
}
@media screen and (max-width: 1440px) {
  .anchor-list-container {
    top: -30px;
    padding-left: 5%;
    .anchor-item {
      font-size: 14pt;
      padding: 0 30px;
      line-height: 60px;
      height: 60px;
    }
  }
}
@media screen and (max-width: 1080px) {
  .anchor-list-container {
    top: -24px;
    .anchor-item {
      font-size: 12pt;
      padding: 0 10px;
      line-height: 48px;
      height: 48px;
    }
  }
}
@media screen and (max-width: 768px) {
  .anchor-list-container {
    box-sizing: border-box;
    top: 0;
    transform: translateY(-50%);
    width: 90%;
    padding-left: 0;
    margin: 0 auto;
    margin-bottom: -40px;
    .anchor-item {
      font-size: 10pt;
      width: 100%;
      padding: 0 10px;
      line-height: 36px;
      height: 36px;
      border-bottom: 1px solid darken(@assistColor, 10%);
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>