// 公共的远程获取数据的代码
// 具体的组件中，需要提供一个远程获取数据的方法  fetchData
import { getBanners } from "@/api/banner.js";
export default function(sort) {
  return {
    data() {
      return {
        bannerInfo: {},
      };
    },
    methods: {
      async getBanner(sort) {
        var bannerInfo = await getBanners(sort);
        this.$set(this.bannerInfo, "pic", window.website + bannerInfo.pic);
        this.$set(this.bannerInfo, "text", bannerInfo.text);
      },
    },
    created() {
      this.getBanner(sort);
    },
  };
}
