import axios from "./request";
import {delHtmlTag} from '@/utils';
export async function getHomeList(sort) {
  var result = await axios.get(`/api.php/list/${sort}`);
  var list = result.map((item) => {
    return {
      title: item.title,
      content: delHtmlTag(item.content),
      link: item.outlink,
      linkAnchor:item.filename ? "#" + item.filename : "",
      subtitle: item.subtitle,
      ico: window.website + item.ico,
      linkText:item.ext_wlm,
      anchor: "home" + item.id
    };
  });
  return list;
}
