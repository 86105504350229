<template>
  <div class="banner-container">
    <div
      class="banner-img"
      :class="{ isHome }"
      :style="{
        backgroundImage: `url(${src})`,
      }"
    ></div>
    <div class="text" v-if="text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.banner-img {
  padding-top: 23.4%;
  &.isHome {
    padding-top: 33%;
  }
  .bgImg(cover);
}
.text {
  position: absolute;
  width: 80%;
  max-width: 780px;
  font-weight: bold;
  font-size: 36pt;
  top: 25%;
  left: 10%;
  color: @mainColor;
}
@media screen and (max-width: 1440px) {
  .text {
    position: absolute;
    width: 86%;
    max-width: none;
    font-weight: bold;
    font-size: 28pt;
    top: 20%;
    left: 5%;
  }
}
@media screen and (max-width: 1080px) {
  .banner-img {
    padding-top: 35%;
    &.isHome {
      padding-top: 50%;
    }
  }
  .text {
    width: 86%;
    font-size: 24pt;
    top: 25%;
  }
}
@media screen and (max-width: 768px) {
  .banner-img {
    padding-top: 60%;
    &.isHome {
      padding-top: 80%;
    }
  }
  .text {
    width: 90%;
    font-size: 16pt;
    left: 5%;
  }
}
</style>