import axios from "./request";

export async function getSiteInfo() {
  const result = await axios.post("/api.php/cms/site");
  const { title, copyright, icp, logo, keywords, description } = result;
  return {
    title,
    copyright,
    icp,
    logo: window.website + logo,
    keywords,
    description,
  };
}
export async function getSiteLabel() {
  const result = await axios.post("/api.php/cms/label");
  return {
    logo: window.website + result.dlogo,
    logowb: result.logowb,
  };
}
