<template>
  <div class="team-container">
    <Header />
    <Banner
      v-if="bannerInfo.pic"
      :src="bannerInfo.pic"
      :text="bannerInfo.text"
    />
    <ul class="team-list center">
      <li class="team-item" v-for="item in teamList" :key="item.id">
        <div class="character">
          <div
            class="photo"
            :style="{
              backgroundImage: `url(${item.ico})`,
            }"
          ></div>
          <div class="text">
            <div class="fullname">{{item.title}}</div>
            <div class="position">{{item.subtitle}}</div>
          </div>
        </div>
        <div class="info" v-html="item.content"></div>
      </li>
    </ul>
    <Footer />
  </div>
</template>

<script>

import Header from "@/components/Header";
import Banner from "@/components/Banner";
import Footer from "@/components/Footer";
import mainScroll from "@/mixins/mainScroll";
import getBanner from "@/mixins/getBanner";
import { getTeamList } from "@/api/teamList";
export default {
  mixins: [getBanner(4), mainScroll()],
  data() {
    return {
      teamList:[]
    };
  },
  components: {
    Header,
    Banner,
    Footer,
  },
  async created() {
    this.teamList = await this.getList(4);
  },
  methods: {
    async getList(sort) {
      const result = await getTeamList(sort);
      return result;
    },
  },
};
</script>

<style lang="less">
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
ul.team-list {
  li.team-item {
    padding: 80px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid @assistColor;
    .character {
      width: 25%;
      .photo {
        .bgImg(cover);
        padding-top: 111.15%;
        margin-bottom: 30px;
      }
      .fullname {
        font-size: 36pt;
        font-family: "Raleway-b";
        color: @mainColor;
        margin-bottom: 10px;
      }
      .position {
        font-size: 18pt;
        color: @mainColor;
      }
    }
    .info {
      width: 65%;
      flex: none;
      p {
        margin-bottom: 30px;
        font-size: 16pt;
        line-height: 1.3;
        color: @gray;
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  ul.team-list {
    li.team-item {
      padding: 60px 0;
      .character {
        .photo {
          margin-bottom: 20px;
        }
        .fullname {
          font-size: 24pt;
        }
        .position {
          font-size: 14pt;
          color: @mainColor;
        }
      }
      .info {
        p {
          font-size: 12pt;
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  ul.team-list {
    li.team-item {
      padding: 40px 0;
      .character {
        width: 30%;
        .photo {
          margin-bottom: 10px;
        }
        .fullname {
          font-size: 20pt;
        }
        .position {
          font-size: 14pt;
          color: @mainColor;
        }
      }
      .info {
        width: 65%;
        p {
          font-size: 10pt;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  ul.team-list {
    li.team-item {
      padding: 20px 0;
      flex-wrap: wrap;
      .character {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 10px;
        .photo {
          width: 30%;
          padding-top: calc(111.15% * 0.3);
          margin-bottom: 0;
        }
        .text {
          width: 70%;
          padding-left: 20px;
          box-sizing: border-box;
          .fullname {
            font-size: 16pt;
            margin-bottom: 5px;
          }
          .position {
            font-size: 12pt;
            color: @mainColor;
          }
        }
      }
      .info {
        width: 100%;
        p {
          font-size: 10pt;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>