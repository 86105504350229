<template>
  <div class="about-container">
    <Header />
    <Banner
      v-if="bannerInfo.pic"
      :src="bannerInfo.pic"
      :text="bannerInfo.text"
    />
    <AnchorList :list="aboutList" />
    <div class="module about01 center" id="about0">
      <div class="title">{{ about1.title }}</div>
      <div class="conts" v-html="about1.content"></div>
    </div>
    <div class="module about02 center" id="about1">
      <div class="title">{{ about2.title }}</div>
      <div class="conts" v-html="about2.content"></div>
      <ul class="about-list">
        <li class="about-item" v-for="item in iconList" :key="item.id">
          <div class="icon">
            <img :src="item.ico" :alt="item.title" />
          </div>
          <div class="text">
            <div class="title">{{ item.title }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </li>
        <li
          class="about-item contact"
          :style="{
            backgroundImage: `url(${aboutBg})`,
          }"
        >
          <div class="text">
            <div class="title">Have an enquiry? Feel free to contact us.</div>
            <router-link :to="{ name: 'Contact' }" class="contact-link"
              >Contact Us</router-link
            >
          </div>
        </li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import aboutBg from "@/assets/img/about-bg.jpg";

import Header from "@/components/Header";
import Banner from "@/components/Banner";
import AnchorList from "@/components/AnchorList";
import Footer from "@/components/Footer";
import mainScroll from "@/mixins/mainScroll";
import getBanner from "@/mixins/getBanner";
import { getAboutList } from "@/api/aboutList";
import { getAboutCont } from "@/api/aboutContent";
import { getAboutIconList } from "@/api/aboutIconList";
export default {
  mixins: [mainScroll(), getBanner(2)],
  data() {
    return {
      aboutBg,
      aboutList: [],
      about1: {},
      about2: {},
      iconList: [],
    };
  },
  async created() {
    this.aboutList = await this.getList(2);
    this.about1 = await this.getCont(64);
    this.about2 = await this.getCont(66);
    this.iconList = await this.getIconList(5);
    if(this.$route.hash){
    location.href = this.$route.hash;
    }
  },
  methods: {
    async getList(sort) {
      const result = await getAboutList(sort);
      return result;
    },
    async getCont(cont) {
      const result = await getAboutCont(cont);
      return result;
    },
    async getIconList(sort) {
      const result = await getAboutIconList(sort);
      return result;
    },
  },
  components: {
    Header,
    Banner,
    AnchorList,
    Footer,
  },
};
</script>

<style lang="less" >
@import "~@/assets/css/global.less";
@import "~@/assets/css/var.less";
.module {
  padding: 80px 0;
  .title {
    font-size: 36pt;
    color: @mainColor;
    font-family: "Raleway-b";
    margin-bottom: 50px;
  }
  .conts {
    font-size: 16pt;
    color: @gray;
    p {
      margin-bottom: 20px;
    }
  }
}

.about01 {
  border-bottom: 1px solid @assistColor;
  .conts {
    column-count: 2;
    column-gap: 50px;
    p {
      text-align: justify;
    }
  }
}
@media screen and (max-width: 1440px) {
  .module {
    padding: 60px 0;
    .title {
      font-size: 32pt;
      margin-bottom: 30px;
    }
    .conts {
      font-size: 14pt;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .about01 {
    .conts {
      column-count: 2;
      column-gap: 30px;
    }
  }
}
@media screen and (max-width: 1080px) {
  .module {
    padding: 40px 0;
    .title {
      font-size: 26pt;
      margin-bottom: 20px;
    }
  }
}
@media screen and (max-width: 768px) {
  .module {
    padding: 20px 0;
    .title {
      font-size: 14pt;
      margin-bottom: 10px;
    }
    .conts {
      font-size: 10pt;
      p {
        margin-bottom: 10px;
      }
    }
  }
  .about01 {
    .conts {
      column-count: 1;
      column-gap: 0;
    }
  }
}
.about02 {
  ul.about-list {
    margin-top: 80px;
    background-color: @lightGray;
    padding: 50px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    li.about-item {
      width: 50%;
      padding-right: 5%;
      margin-bottom: 80px;
      box-sizing: border-box;
      .icon {
        width: 150px;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
      }
      .title {
        font-size: 24pt;
        margin-bottom: 30px;
      }
      .desc {
        font-size: 16pt;
        color: @gray;
      }
      &.contact {
        .bgImg(cover);
        padding-top: calc(50% * 0.67);
        position: relative;
        .text {
          width: 65%;
          position: absolute;
          left: 10%;
          top: 50%;
          z-index: 2;
          transform: translateY(-50%);
          .title {
            color: #fff;
            font-size: 36pt;
            margin: 0 auto 50px;
          }
          .contact-link {
            font-family: "Raleway-b";
            font-size: 16pt;
            color: @mainColor;
            background-color: @assistColor;
            display: inline-block;
            padding: 20px 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .about02 {
    ul.about-list {
      margin-top: 60px;
      padding: 40px;
      li.about-item {
        margin-bottom: 50px;
        .icon {
          width: 120px;
          margin-bottom: 20px;
        }
        .title {
          font-size: 20pt;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 14pt;
        }
        &.contact {
          .text {
            width: 80%;
            .title {
              font-size: 28pt;
              margin: 0 auto 30px;
            }
            .contact-link {
              font-size: 14pt;
              padding: 10px 20px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  .about02 {
    ul.about-list {
      margin-top: 40px;
      padding: 30px;
      li.about-item {
        margin-bottom: 40px;
        .icon {
          width: 100px;
          margin-bottom: 10px;
        }
        .title {
          font-size: 16pt;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 12pt;
        }
        &.contact {
          .text {
            width: 80%;
            .title {
              font-size: 20pt;
              margin: 0 auto 20px;
            }
            .contact-link {
              font-size: 12pt;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .about02 {
    ul.about-list {
      margin-top: 20px;
      padding: 15px;
      li.about-item {
        width: 100%;
        margin-bottom: 30px;
        padding-right: 0;
        .icon {
          width: 80px;
          margin: 0 auto 5px;
        }
        .title {
          font-size: 12pt;
          text-align: center;
          margin-bottom: 10px;
        }
        .desc {
          font-size: 10pt;
          text-align: center;
        }
        &.contact {
          padding-top: 100%;
          .text {
            width: 80%;
            .title {
              font-size: 20pt;
              text-align: left;
              margin: 0 auto 20px;
            }
            .contact-link {
              font-size: 12pt;
            }
          }
        }
      }
    }
  }
}
</style>