import nav from "@/api/nav";
export default {
  namespaced: true,
  state: {
    navList: [],
  },
  mutations: {
    setNavList(state, payload) {
      state.navList = payload;
    },
  },
  actions: {
    async getNavList(ctx) {
      if (!ctx.state.navList) {
        return;
      }
      const resp = await nav();
      ctx.commit("setNavList", resp);
    },
  },
};
